<template>
  <b-modal
    dialog-class="quicksearch-registry-modal"
    id="quickSearchRegistryModal"
    size="xl"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people"></b-icon>
        <span>Cerca Anagrafiche</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <b-form @submit.prevent="onSearch(filterName)">
      <div class="mt-2">
        <b-card class="filter">
          <b-row>
            <div class="col-md-6">
              <base-input
                name="RFRNAME"
                v-model="filter.byAttribute.title"
                type="text"
                label="Nominativo"
                placeholder="Inserisci un nominativo"
              />
            </div>
            <div class="col-md-6 align-self-center actions-wrapper">
              <b-button type="submit" variant="lisaweb" size="sm"
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
          <b-button-group
            v-b-toggle:collapse-3
            class="my-2 filter-button-group"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>
          <b-collapse id="collapse-3" class="mt-2" v-model="visible">
            <b-row>
              <div class="col-md-6">
                <base-input
                  name="NINO"
                  type="text"
                  label="Codice Fiscale"
                  v-model="filter.byAttribute.NINO"
                  placeholder="Inserisci un codice fiscale"
                />
              </div>
              <div class="col-md-6">
                <base-input
                  name="creg"
                  type="text"
                  label="Partita IVA"
                  v-model="filter.byCustom.CREG.value"
                  placeholder="Inserisci una partita IVA"
                />
              </div>
              <div class="col-md-6">
                <base-select
                  name="salesman"
                  label="Produttore"
                  v-model="filter.byBroker.id"
                  :options="salesmen"
                  :multiple="false"
                />
              </div>
              <div class="col-md-6">
                <base-select
                  name="localita"
                  label="Località"
                  v-model="filter.byCorrespondence.CITY"
                  placeholder="Digitare le lettere iniziali..."
                  :options="ajaxOptions"
                  @search-change="getAjaxOptions"
                  :isLoading="isLoadingAjax"
                />
              </div>
              <div class="col-md-12">
                <base-textarea
                  name="ADDR"
                  v-model="filter.byCustom.ADDR.value"
                  label="Indirizzo"
                  placeholder="Inserisci un indirizzo"
                />
              </div>
            </b-row>
          </b-collapse>
        </b-card>
      </div>
    </b-form>
    <registries
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: [
          'byCorrespondence',
          'byRegistryGroup',
          'byInsurancePolicy',
          'byBroker',
          'byRegistry',
        ],
      }"
      :ref="tableRef"
      noInnerWidth
      :onlyActions="['infomodal']"
      noCustom
      includeRowSelector
      @rowSelector="onModalSelector"
      noInputPaginator
    >
    </registries>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions, mapGetters } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterQuickSearchRegistry",
      repository: "registry",
      resource: "registries",
      tableRef: "QuickSearchRegistryTableRef",
      visible: false,
      isLoadingAjax: false,
      ajaxOptions: [],
      salesmen: [],
      fields: [
        {
          key: "rowSelector",
          label: "",
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "attributables.SURN",
          label: "Cognome",
        },
        {
          key: "attributables.NAME",
          label: "Nome",
        },
        {
          key: "attributables.CNAM",
          label: "Ragione Sociale",
        },
        {
          key: "attributables.NINO",
          label: "Codice Fiscale",
        },
        {
          key: "attributables.CREG",
          label: "Partita IVA",
        },
        {
          key: "insurance_policy",
          value: (item) =>
            item.insurance_policies.length
              ? item.insurance_policies.length
              : "no pol",
          label: this.getDictionary("insurance_policies"),
        },
        {
          key: "primary_group",
          value: (item) =>
            item.registry_groups.length
              ? item.registry_groups.find(
                  (registry_group) => registry_group.pivot.primary.value === "Y"
                ).code
              : "",
          label: this.getDictionary("Gruppo Primario"),
        },
        {
          key: "broker",
          value: (item) =>
            item.insurance_policies
              ? item.insurance_policies
                  .filter((e) => {
                    return e.broker && e.broker.status_broker.value === 0;
                  })
                  // filtrare solo quelle che si trovano in stati particolari:
                  // 0: non perfezionata,
                  // 1: perfezionata
                  // 2: sospesa
                  .filter((e) => {
                    return (
                      e.status_policy &&
                      [0, 1, 2].includes(e.status_policy.value)
                    );
                  })
                  .map((e) => e.broker.attributables.BUID)
                  .filter((value, index, e) => e.indexOf(value) === index)
                  .join(", ")
              : "",
          label: this.getDictionary("salesman"),
        },
        {
          key: "address",
          label: this.getDictionary("attribute_ADDR_value", "correspondence"),
          sortable: false,
        },
      ],
    };
  },
  components: {
    Registries: () => import("@/components/tables/Registries"),
    BaseInput,
    BaseTextarea,
    BaseSelect,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          CNAME: null,
          NINO: null,
          title: null,
        },
        byBroker: {
          id: null,
        },
        byCustom: {
          CREG: {
            value: null,
            likewise: 2,
            filter: "byAttribute",
          },
          ADDR: {
            value: null,
            likewise: 4,
            filter: "byCorrespondence",
          },
        },
        byCorrespondence: {
          CITY: null,
        },
      };
      return init;
    },
    onSearch(name) {
      this.visible = false;
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
    },
    getAjaxOptions(query) {
      if (query.length >= 2) {
        this.isLoadingAjax = true;
        this.ajaxOptions = this.getCouncils()(null, query);
        this.isLoadingAjax = false;
      }
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getCouncils: "councils",
    }),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onModalSelector(val) {
      this.$emit("input", val);
    },
  },
  beforeMount() {
    /*  */
    // console.log("beforeCreate Registries (quickSearchRegistry)");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("countries"))
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("councils"))
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("provinces"))
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log(
            "All promises went through correctly (quickSearchRegistry)"
          );
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(
            "One or more promises crashed... :| (quickSearchRegistry)"
          );
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /*  */
  },
  mounted() {
    this.salesmen = this.getSalesmen();
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId !== "quickSearchRegistryModal") return;
      this.onClearFilter(this.filterName);
    });
  },
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.actions-wrapper {
  margin-top: 5px;
}
</style>
